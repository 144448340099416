import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import api from 'axios'
import { useForm } from 'react-hook-form'

import { Grid, GridColumn, GridRow } from '../components/Grid'
import Layout from '../components/Layout'
import SEO from '../components/Helpers/seo'
import Welcome from '../components/Welcome'
import WorkInfo from '../components/WorkInfo'

export const query = graphql`
{
  markdownRemark(fileAbsolutePath: {regex: "/(work/_index)/"}) {
    frontmatter {
      seo {
        seo_description
        seo_title
      }
      welcome {
        welcome_content
        welcome_title
        welcome_image
      }
    }
  }
}
`

const Work = ({ data }) => {
  const WorkData = data.markdownRemark.frontmatter
  const { register, handleSubmit } = useForm()
  const [formStatus, setFormStatus] = useState('')

  const onSubmit = values => {
    const data = {}
    const formElements = Object.entries(values)
    formElements.map(([key, value]) => (data[key] = value))

    api({
      method: 'POST', 
      url: 'https://zomb927yq5.execute-api.us-east-1.amazonaws.com/dev/static-site-mailer', 
      data: JSON.stringify(data)
    }).then((response)=>{
      if (response.status === 200) {
        setFormStatus('success')
      } else {
        setFormStatus('error')
      }
    }).catch((errors) => {
      setFormStatus('error')
    })
  }
  
  return(
    <Layout>
      <SEO title={ WorkData.seo.seo_title  } description={ WorkData.seo.seo_description }/>
      
      <Welcome
        title={ WorkData.welcome.welcome_title }
        image={ WorkData.welcome.welcome_image }
        content={ WorkData.welcome.welcome_content } />

      <Grid>
        <GridRow>
          <GridColumn>
            <WorkInfo />
          </GridColumn>

          <GridColumn>
          { formStatus === '' &&
            <form onSubmit={ handleSubmit(onSubmit) }>
                <input type="hidden" name="ses_address" defaultValue="infrati@egbsystems.com.br" ref={register} />

                <input type="hidden" name="send_to" defaultValue="gestaodepessoas@egbsystems.com.br" ref={register} />

                <input type="hidden" name="subject" defaultValue="Teste: Mensagem enviada via site: Trabalhe Conosco" ref={register} />

                  <input type="hidden" name="form-name" value="contact" />
                  
                  <div className="form__row">
                    <label htmlFor="full_name">Nome completo</label>
                    <input type="text" name="nome_completo" id="full_name" ref={register({ required: true })} />
                  </div>

                  <div className="form__row">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" ref={register({ required: true })} />
                  </div>

                  <div className="form__row">
                    <label htmlfor="role">Cargo</label>
                    <input type="text" name="cargo" id="role"  ref={register({ required: true })} />
                  </div>

                  <div className="form__row">
                    <label htmlFor="linkedin">Linkedin</label>
                    <input type="text" name="linkedin" id="linkedin" ref={register({ required: true })} />
                  </div>

                  <div className="form__row">
                    <label htmlFor="message">Apresentação</label>
                    <textarea name="apresentacao" id="message" rows="10" ref={register({ required: true })}></textarea>
                  </div>

                  <div className="form__row-radio">
                    <input type="checkbox" name="accept" id="accept" ref={register({ required: true })} />
                    <label htmlFor="accept">Ao clicar em enviar, você confirma que leu e concorda com o nosso <Link to="/termo-de-consentimento">termo de consentimento</Link></label>
                  </div>

                  <div className="form__button">
                    <button type="submit" className="form__button">Enviar</button>
                  </div>
                </form>
            } 

            { formStatus === 'success' &&
                <div className="form__validation form__validation--success">
                  <p><strong>Currículo enviado com sucesso.</strong>
                  <br />Entraremos em contato quando houver vagas.</p>
                </div>
            }
            
            { formStatus === 'error' &&
                <div className="form__validation form__validation--error">
                  <p>Mensagem não enviada. Tente novamente ou envie um email para contato@egbsystems.com.br</p>
                </div>
            }
          </GridColumn>
        </GridRow>
      </Grid>

    </Layout>
  )
}

export default Work

